import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <article>
      <header>
        <div>
          <h1><a href="https://www.linkedin.com/in/dpehrson/" title="Connect with Daniel Pehrson on LinkedIn">Daniel Pehrson</a></h1>
          <p>Software architect, Outdoor enthusiast</p>
        </div>
        <nav>
          <a href="#body" title="Jump to content">&#8659;</a>
        </nav>
      </header>
    </article>
  </Layout>
)

export default IndexPage
